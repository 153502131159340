<template>
  <div>
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-left pr-1 mb-0">Expense</h2>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active">
                  <span aria-current="location"> Edit </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-form method="post" @submit="updateExpenses">
      <b-card>
        <b-row>
          <b-col md="6">
            <b-form-group label="Hatch Id">
              <validation-provider
                #default="{ errors }"
                name="Hatch Id"
                rules="required"
              >
                <b-form-input
                  v-model="hatch_id"
                  type="text"
                  name="hatch_id"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="Rent" label="Rent">
              <div class="form-label-group">
                <b-form-input
                  v-model="FormData.rent"
                  placeholder="Rent"
                  type="text"
                  name="rent"
                />
              </div>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label-for="Fuel" label="Fuel">
              <div class="form-label-group">
                <b-form-input
                  v-model="FormData.fuel"
                  placeholder="Fuel"
                  type="text"
                  name="fuel"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="Electricity" label="Electricity">
              <div class="form-label-group">
                <b-form-input
                  v-model="FormData.electricity"
                  placeholder="Electricity"
                  type="text"
                  name="electricity"
                />
              </div>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label-for="Labour Salary" label="Labour Salary">
              <div class="form-label-group">
                <b-form-input
                  v-model="FormData.labour_sal"
                  placeholder="Labour Salary"
                  type="text"
                  name="labour_sal"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="Medical" label="Medical">
              <div class="form-label-group">
                <b-form-input
                  v-model="FormData.medical"
                  placeholder="Medical"
                  type="text"
                  name="medical"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="Water" label="Water">
              <div class="form-label-group">
                <b-form-input
                  v-model="FormData.water"
                  placeholder="Water"
                  type="text"
                  name="water"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="Other" label="Other">
              <div class="form-label-group">
                <b-form-input
                  v-model="FormData.other"
                  placeholder="Other"
                  type="text"
                  name="other"
                />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <!-- submit and reset -->
        <b-col>
          <b-button type="submit" variant="primary" value="Submit" class="mr-1">
            Submit
          </b-button>
        </b-col>
      </b-card>
    </b-form>
  </div>
</template>

<script>
import * as Vue from "vue";
import axiosIns from "@/libs/axios";

import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BFormSelect,
  BFormDatepicker,
  BInputGroupAppend,
} from "bootstrap-vue";
import { useRoute } from "vue-router";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BFormSelect,
    BFormDatepicker,
  },
  data() {
    return {
      selected: null,
      FormData: {
        rent: null,
        fuel: null,
        electricity: null,
        labour_sal: null,
        medical: null,
        water: null,
        other: null,
      },
      hatch_id: {},
      hatchList: [],
    };
  },
  created: function () {
    this.getExpenseById();
  },
  methods: {
    getExpenseById() {
      const id = this.$route.params.expenseId;
      axiosIns
        .get(`web/expenses/${id}`)
        .then((response) => {
        
          this.hatch_id = response.data.expense.hatch_id;
          this.FormData.rent = response.data.expense.rent;
          this.FormData.rent = response.data.expense.rent;
          this.FormData.fuel = response.data.expense.fuel;
          this.FormData.electricity = response.data.expense.electricity;
          this.FormData.labour_sal = response.data.expense.labour_sal;
          this.FormData.medical = response.data.expense.medical;
          this.FormData.water = response.data.expense.water;
          this.FormData.other = response.data.expense.other;
          this.id = id;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },

    updateExpenses(e) {
      e.preventDefault();
      var data = new FormData();
      data.append("rent", this.FormData.rent);
      data.append("fuel", this.FormData.fuel);
      data.append("electricity", this.FormData.electricity);
      data.append("labour_sal", this.FormData.labour_sal);
      data.append("medical", this.FormData.medical);
      data.append("water", this.FormData.water);
      data.append("other", this.FormData.other);
      data.append("_method", "PUT");
      const id = this.$route.params.expenseId;
      axiosIns
        .post(`web/expenses/${id}`, data)
        .then((response) => {
          response;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "warning",
              text: `Data Updated Successfully `,
            },
          });
          setTimeout(
            this.$router.push({
              name: `apps-expense-list`,
            }),
            3000
          );
        })

        .catch((error) => {
          // (error.response);
          this.$refs.setErrors(error.response.data.error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
